/* Copy the relevant styles from UserManagement.module.css */
.userDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.userDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #555;
  font-size: 0.95rem;
  cursor: pointer;
  padding: 0.5rem 0;
}

.backButton:hover {
  color: #000;
}

.userStatus {
  display: flex;
  align-items: center;
}

.userStatus span {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
}

.userDetailContent {
  padding: 1.5rem;
}

.userOverview {
  margin-bottom: 1.5rem;
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.userDetailProfile {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.userAvatar {
  width: 80px;
  height: 80px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.userInfo {
  flex: 1;
}

.userInfo h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
}

.userInfo p {
  margin: 0.25rem 0;
  color: #555;
}

.userId {
  font-size: 0.85rem;
  color: #777;
}

.userMeta {
  display: flex;
  gap: 1rem;
  margin-top: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
}

.userMeta span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1;
  height: 1.5rem;
}

.userRole {
  background-color: #e3f2fd;
  color: #1976d2;
}

.userJoined {
  color: #666;
  font-size: 0.85rem;
  padding: 0 !important;
  height: auto !important;
  background: none !important;
}

/* Status badges */
.status-active {
  background-color: #4caf50;
  color: white;
}

.status-suspended {
  background-color: #ff9800;
  color: white;
}

.status-banned {
  background-color: #f44336;
  color: white;
}

.status-deleted {
  background-color: #9e9e9e;
  color: white;
}

/* Action buttons styling */
.actionButtons {
  display: flex;
  gap: 0.75rem;
  margin: 1.5rem 0;
}

.actionButtons button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.userDetailTabs {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  background-color: white;
  border-radius: 8px 8px 0 0;
  padding: 0 16px;
}

.userDetailTabs button {
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.userDetailTabs button:hover {
  color: var(--dark-brown);
}

.userDetailTabs button.activeTab {
  color: var(--dark-brown);
}

.userDetailTabs button.activeTab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--dark-brown);
}

.userDetailTabContent {
  background-color: white;
  border-radius: 0 0 8px 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.userDetailSection {
  margin-bottom: 24px;
}

.userDetailSection h3 {
  margin: 0 0 16px 0;
  font-size: 1.1rem;
  color: var(--dark-brown);
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.detailGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailLabel {
  font-size: 0.85rem;
  color: #666;
}

.detailValue {
  font-weight: 500;
}

.activitySummary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.activityItem {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.activityCount {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: var(--dark-brown);
  margin-bottom: 4px;
}

.activityLabel {
  font-size: 0.9rem;
  color: #666;
}

.emptyState {
  background-color: #f9f9f9;
  padding: 24px;
  text-align: center;
  border-radius: 8px;
  color: #666;
}

.loadingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
  gap: 16px;
}

.errorState {
  background-color: #fff8f8;
  border: 1px solid #ffdddd;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  color: #d32f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.errorState h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #d32f2f;
}

.errorState p {
  margin: 0;
  color: #666;
}

.errorState button {
  margin-top: 8px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.errorState button:hover {
  background-color: #e0e0e0;
}

.contentSection {
  margin-bottom: 24px;
}

.contentSection h3 {
  margin: 0 0 16px 0;
  font-size: 1.1rem;
  color: var(--dark-brown);
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.contentList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentItem {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  border-left: 3px solid var(--dark-brown);
  transition: transform 0.2s ease, box-shadow 0.2s ease,
    background-color 0.2s ease;
  cursor: pointer;
}

.contentItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.contentHeader h4 {
  margin: 0;
  font-size: 1rem;
  color: var(--dark-brown);
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.viewIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-brown);
  opacity: 0.7;
  transition: opacity 0.2s ease;
  font-size: 1.1rem;
}

.contentItem:hover .viewIcon {
  opacity: 1;
}

.contentPreview {
  margin: 0 0 12px 0;
  color: #555;
  font-size: 0.9rem;
  line-height: 1.5;
}

.contentMeta {
  display: flex;
  gap: 16px;
  font-size: 0.8rem;
  color: #777;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.contentFilter {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contentFilter select {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
}

/* Status badges for content items */
.status-active {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-flagged {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-removed {
  background-color: #f44336;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-deleted {
  background-color: #9e9e9e;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.contentFilters {
  margin-bottom: 20px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.contentFilter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contentFilter label {
  font-weight: 500;
  color: #555;
}

.contentFilter select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  min-width: 180px;
}

/* Report styles */
.reportsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.reportItem {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  border-left: 3px solid var(--dark-brown);
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.reportType {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reportLabel {
  font-weight: 500;
  color: #555;
  font-size: 0.9rem;
}

.reportValue {
  color: var(--dark-brown);
}

.reportContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.reportReason {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reportDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reportDetailsText {
  margin: 0;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  font-size: 0.9rem;
  color: #333;
}

.reportMeta {
  display: flex;
  gap: 16px;
  font-size: 0.8rem;
  color: #777;
  margin-top: 8px;
}

/* Report status styles */
.status-pending {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-investigating {
  background-color: #2196f3;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-resolved {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-dismissed {
  background-color: #9e9e9e;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.resourceDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border-left: 3px solid #ccc;
  transition: transform 0.2s ease, box-shadow 0.2s ease,
    background-color 0.2s ease;
}

.resourceDetails[style*="cursor: pointer"]:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #e8e8e8;
}

/* Add styles for the scores section */
.scoresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.scoreItem {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scoreValue {
  font-size: 2rem;
  font-weight: 600;
  color: var(--dark-brown);
  margin-bottom: 4px;
}

.scoreLabel {
  font-size: 0.9rem;
  color: #666;
}

/* Admin Actions Tab Styling - Updated naming for consistency */
.adminActionsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.adminActionItem {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #6c757d;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.adminActionItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Color-code different action types */
.adminActionItem[data-action-type="approve_account"] {
  border-left-color: #28a745; /* Green for approvals */
}

.adminActionItem[data-action-type="suspend_account"] {
  border-left-color: #fd7e14; /* Orange for suspensions */
}

.adminActionItem[data-action-type="ban_account"] {
  border-left-color: #dc3545; /* Red for bans */
}

.adminActionItem[data-action-type="restore_account"] {
  border-left-color: #17a2b8; /* Blue for restores */
}

.adminActionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eaeaea;
}

.adminActionType {
  font-weight: 600;
  text-transform: capitalize;
  color: #495057;
}

.adminActionDate {
  font-size: 0.85rem;
  color: #6c757d;
}

.adminActionContent {
  padding: 16px;
}

.adminActionReason,
.adminActionModerator {
  margin-bottom: 12px;
}

.adminActionLabel {
  font-weight: 500;
  color: #495057;
  margin-right: 8px;
  display: inline-block;
  min-width: 100px;
}

.adminActionValue {
  color: #212529;
}

.adminActionMetadata {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed #dee2e6;
}

.adminActionMetadataContent {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.85rem;
  margin-top: 8px;
  overflow-x: auto;
  white-space: pre-wrap;
  color: #495057;
}

.actionButtonWrapper {
  position: relative;
  display: inline-block;
}

.actionButtonTooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.actionButtonWrapper:hover .actionButtonTooltip {
  opacity: 1;
  visibility: visible;
}

/* Add a small arrow at the bottom of the tooltip */
.actionButtonTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}
