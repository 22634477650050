.dropdownContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button base styles */
.dropdownButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Theme variables */
.dropdownButton[data-theme="light"] {
  --button-color: #333;
  --button-hover-bg: rgba(0, 0, 0, 0.05);
}

.dropdownButton[data-theme="dark"] {
  --button-color: #fff;
  --button-hover-bg: rgba(255, 255, 255, 0.1);
}

.dropdownButton[data-theme="transparent"] {
  --button-color: #fff;
  --button-hover-bg: rgba(255, 255, 255, 0.1);
}

/* Apply theme variables */
.dropdownButton {
  color: var(--button-color);
}

.dropdownButton:hover,
.dropdownButton.active {
  background-color: var(--button-hover-bg);
}

/* Dropdown Menu theme variables */
.dropdownMenu[data-theme="light"] {
  --menu-bg: #fff;
  --menu-border: rgba(0, 0, 0, 0.1);
  --menu-text: #333;
  --menu-hover-bg: rgba(0, 0, 0, 0.05);
  --menu-header-color: #666;
  --menu-header-border: rgba(0, 0, 0, 0.1);
}

.dropdownMenu[data-theme="dark"] {
  --menu-bg: #333;
  --menu-border: rgba(255, 255, 255, 0.1);
  --menu-text: #fff;
  --menu-hover-bg: rgba(255, 255, 255, 0.1);
  --menu-header-color: #999;
  --menu-header-border: rgba(255, 255, 255, 0.1);
}

.dropdownMenu[data-theme="transparent"] {
  --menu-bg: rgba(51, 51, 51, 0.9);
  --menu-border: rgba(255, 255, 255, 0.1);
  --menu-text: #fff;
  --menu-hover-bg: rgba(255, 255, 255, 0.1);
  --menu-header-color: #999;
  --menu-header-border: rgba(255, 255, 255, 0.1);
}

/* Apply menu theme variables */
.dropdownMenu {
  background: var(--menu-bg);
  border: 1px solid var(--menu-border);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 4px;
  min-width: 160px;
  z-index: 1000;
  list-style: none;
  margin: 0;
}

.menuItem {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  white-space: nowrap;
  color: var(--menu-text);

  &:focus-visible {
    outline: 2px solid var(--chair-orange);
    outline-offset: -2px;
  }
}

.menuItem:hover {
  background-color: var(--menu-hover-bg);
}

.dropdownHeader {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 0.85rem;
  margin-bottom: 4px;
  cursor: default;
  color: var(--menu-header-color);
  border-bottom: 1px solid var(--menu-header-border);
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .dropdownButton,
  .menuItem {
    transition: none;
  }
}

/* Focus states */
.dropdownButton:focus-visible {
  outline: 2px solid var(--chair-orange);
  outline-offset: 2px;
}

/* Update color classes to use CSS variables */
.success {
  color: var(--status-active) !important;
}

.warning {
  color: var(--status-suspended) !important;
}

.danger {
  color: var(--soft-red) !important;
}

.danger-light {
  color: var(--deep-orange) !important;
}

.default {
  /* Default color is already handled by the theme */
}

.menuItem.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #999;
}

.menuItem.disabled:hover {
  background-color: #f5f5f5;
}
