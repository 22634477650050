.contentManagementContainer {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--dark-brown);
}

.stats {
  display: flex;
  gap: 16px;
}

.statItem {
  background-color: #f9f9f9;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
  min-width: 100px;
}

.statValue {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--dark-brown);
}

.statLabel {
  font-size: 0.85rem;
  color: #666;
}

.filters {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchForm {
  width: 100%;
}

.searchInput {
  display: flex;
  width: 100%;
  position: relative;
}

.searchInput input {
  flex: 1;
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.searchInput button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.filterControls {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

.filterGroup {
  display: flex;
  align-items: center;
}

.filterGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #555;
}

.filterGroup select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 150px;
}

.clearButton {
  margin-left: auto;
}

.tableWrapper {
  flex: 1;
  overflow: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: white;
}

.contentTable {
  width: 100%;
  border-collapse: collapse;
}

.contentTable th,
.contentTable td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.contentTable th {
  background-color: #f9f9f9;
  font-weight: 600;
  color: #555;
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
  padding: 12px 16px;
}

.sortableHeader {
  cursor: pointer;
  position: relative;
  padding-right: 24px !important;
}

.sortIcon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.contentRow {
  background-color: #f9f9f9;
  cursor: pointer;
}

.contentRow:hover {
  background-color: #f0f0f0;
}

.contentDataCell {
  cursor: pointer;
}

.contentDataCell:hover {
  color: var(--dark-brown);
}

.contentPreview {
  display: flex;
  flex-direction: column;
}

.contentTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

.contentExcerpt {
  font-size: 0.85rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionCell {
  width: 80px;
  text-align: center;
}

.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.noResults {
  text-align: center;
  padding: 32px;
  color: #666;
}

/* Status badges */
.status-active {
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-flagged {
  background-color: #ff9800;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-removed {
  background-color: #f44336;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.status-deleted {
  background-color: #9e9e9e;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.checkboxFilters {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filterLabel {
  font-weight: 500;
  color: #555;
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.checkboxGroup input[type="checkbox"] {
  cursor: pointer;
}
