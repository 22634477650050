.contentDetailContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.contentDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #555;
  font-size: 0.95rem;
  cursor: pointer;
  padding: 0.5rem 0;
}

.backButton:hover {
  color: #000;
}

.contentStatus {
  display: flex;
  align-items: center;
}

.contentStatus span {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
}

.contentDetailContent {
  padding: 1.5rem;
  flex: 1;
  overflow-y: auto;
}

.contentOverview {
  margin-bottom: 1.5rem;
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.contentOverview h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--dark-brown);
}

/* Action buttons styling */
.actionButtons {
  display: flex;
  gap: 0.75rem;
  margin: 1.5rem 0;
}

.actionButtons button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infoSection,
.contentSection,
.moderationSection,
.reportsSection {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.infoSection h2,
.contentSection h2,
.moderationSection h2,
.reportsSection h2 {
  display: none; /* Hide h2 elements as we're using h3 now */
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.infoLabel {
  font-weight: 600;
  color: #666;
  font-size: 0.9rem;
}

.infoValue {
  font-size: 1rem;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
}

.contentBody {
  line-height: 1.6;
}

.contentBody h3 {
  margin-top: 0;
  color: var(--dark-brown);
}

.moderationHistory,
.reportsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.moderationAction,
.reportItem {
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 12px;
}

.actionHeader,
.reportHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.actionType,
.reportStatus {
  font-weight: 600;
}

.actionDate,
.reportDate {
  color: #666;
  font-size: 0.9rem;
}

.actionDetails,
.reportDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.actionBy,
.reportedBy {
  font-size: 0.9rem;
}

.actionReason,
.reportReason,
.reportDescription {
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Status badges for uniform styling */
.status-active,
.status-pending {
  background-color: #4caf50;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block; /* Prevent stretching */
  white-space: nowrap;
}

.status-flagged,
.status-investigating {
  background-color: #ff9800;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block; /* Prevent stretching */
  white-space: nowrap;
}

.status-removed,
.status-resolved {
  background-color: #f44336;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block; /* Prevent stretching */
  white-space: nowrap;
}

.status-deleted,
.status-dismissed {
  background-color: #9e9e9e;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block; /* Prevent stretching */
  white-space: nowrap;
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
}

.modal h2 {
  margin-top: 0;
  color: var(--dark-brown);
}

.reasonTextarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 16px 0;
  font-family: inherit;
  resize: vertical;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Loading and error states */
.loadingContainer,
.errorContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666;
}

.errorContainer {
  gap: 16px;
}

.authorLink {
  color: var(--dark-brown);
  cursor: pointer;
  text-decoration: underline;
}

.authorLink:hover {
  text-decoration: none;
}

.pseudonym {
  font-style: italic;
  color: #666;
}

.experienceText {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.intentsSection {
  margin-top: 16px;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.intentsSection h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #555;
}

.intentsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.intent {
  background-color: #e9f5ff;
  color: #0066cc;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.85rem;
}

.dateInfo {
  margin-top: 16px;
  color: #666;
}

.dateLabel {
  font-weight: 600;
  margin-right: 8px;
}

.permissionsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.permissionEnabled,
.permissionDisabled {
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
}

.permissionEnabled {
  background-color: #e6f7e6;
  color: #2e7d32;
}

.permissionDisabled {
  background-color: #f5f5f5;
  color: #9e9e9e;
  text-decoration: line-through;
}

/* Additional style for reports to make them clickable */
.reportItem {
  cursor: pointer;
  transition: background-color 0.2s;
}

.reportItem:hover {
  background-color: #f0f0f0;
}

.contentDetailProfile {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.contentMeta {
  display: flex;
  gap: 1rem;
  margin-top: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
}

.contentCreated {
  color: #666;
  font-size: 0.85rem;
}

.infoSection h3,
.contentSection h3,
.moderationSection h3,
.reportsSection h3 {
  margin: 0 0 16px 0;
  font-size: 1.1rem;
  color: var(--dark-brown);
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* Tabs Styling */
.contentDetailTabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin: 20px 0;
}

.contentDetailTabs button {
  padding: 10px 20px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contentDetailTabs button:hover {
  color: #333;
  background-color: #f5f5f5;
}

.contentDetailTabs button.activeTab {
  color: #0077cc;
  border-bottom: 2px solid #0077cc;
  font-weight: 600;
}

/* Audit Log Tab Styling */
.auditLogTab {
  margin: 20px 0;
}

.auditLogSection {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.auditLogSection h3 {
  margin-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.errorState {
  text-align: center;
  padding: 30px 0;
  color: #d32f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.emptyState {
  text-align: center;
  padding: 30px 0;
  color: #666;
  font-style: italic;
}

.adminActionsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.adminActionItem {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 15px;
  background-color: #fbfbfb;
  transition: box-shadow 0.2s ease;
}

.adminActionItem:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.adminActionItem[data-action-type*="FLAG"] {
  border-left: 4px solid #ff9800;
}

.adminActionItem[data-action-type*="REMOVE"] {
  border-left: 4px solid #d32f2f;
}

.adminActionItem[data-action-type*="RESTORE"] {
  border-left: 4px solid #4caf50;
}

.adminActionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.adminActionType {
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
  font-size: 14px;
}

.adminActionDate {
  color: #777;
  font-size: 13px;
}

.adminActionContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.adminActionReason,
.adminActionModerator,
.adminActionMetadata {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.adminActionLabel {
  font-weight: 500;
  color: #666;
  width: 100px;
  flex-shrink: 0;
}

.adminActionValue {
  color: #333;
  flex-grow: 1;
}

.adminActionMetadataContent {
  background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: monospace;
  margin: 0;
  white-space: pre-wrap;
  max-height: 120px;
  overflow: auto;
}

/* Adjust existing styles to work with the tab interface */
.contentInfo {
  margin-top: 0;
}

/* Status colors for action types */
.adminActionItem[data-action-type="FLAG_CONTENT"] .adminActionType {
  color: #ff9800;
}

.adminActionItem[data-action-type="REMOVE_CONTENT"] .adminActionType {
  color: #d32f2f;
}

.adminActionItem[data-action-type="RESTORE_CONTENT"] .adminActionType {
  color: #4caf50;
}
